<template>
    <div >
      <service v-for="(group, index) in groups" :key="index"
             :group="group"
      />
    </div>
  </template>
  
  <script>
  import Service from "./service.vue";
  export default {
    name: "servicesList",
    components: {Service},
    props: ['groups']
  }
  </script>
  
  <style scoped>
  
  </style>