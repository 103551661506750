<template>
    <div class="container">
        <div class="card">
            <div class="card-header">
                <h5 style="font-size: 18px; font-weight: 700">
                    {{ $t('settings.Services') }}
                </h5>
                <div class="card-header-right" style="right: 15px"><button class="btn btn-primary" @click="createGroup" style="width: 104px">{{ $t('settings.Create') }}</button></div>
            </div>
            <div class="card-body">
                <services-list v-if="!loading" :groups="groups"/>
                <div v-else class="p-t-10">
                    <div class="panel-block">
                        <b-skeleton :animated="true" width="80%"></b-skeleton>
                    </div>
                    <div class="panel-block">
                        <b-skeleton :animated="true" width="89%"></b-skeleton>
                    </div>
                    <div class="panel-block">
                        <b-skeleton :animated="true" width="75%"></b-skeleton>
                    </div>
                    <div class="panel-block">
                        <b-skeleton :animated="true" width="82%"></b-skeleton>
                    </div>
                    <div class="panel-block">
                        <b-skeleton :animated="true" width="77%"></b-skeleton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ServicesList from "@/components/monitoringServices/servicesList";
import {mapGetters} from "vuex";
 export default {
     data() {
        return {
            loading: true,
            isMobile: false
        }
     },
    components: {ServicesList},
    methods: {
        createGroup() {
            this.$router.push({name: 'ServicesMonitoringCreate'})
        },
        getGroups() {
            this.$store.dispatch('serviceMonitoringGroup/getGroupList', {})
                .then(() => {
                    this.loading = false
                })
        }
    },
    computed: {
        ...mapGetters('serviceMonitoringGroup',['groups']),
    },
    mounted() {
        this.getGroups()
    }
 }
</script>