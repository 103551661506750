<template>
<div>
  <div v-show="!isMobile" style="display: flex; margin-bottom: 1rem">
    <div class="d-flex w-100" style="display: flex; align-items: center">
      <div class="group-name" style="display: flex; align-items: center; width: 35vw">
        <h5 style="font-weight: 700;margin-bottom: 0">
          {{ group.name }}
        </h5>
        <div>{{'('+ group.owner.login+')'}}</div>
      </div>

      <h5>
        {{ $t("service.bases") }}: {{ group.services_count }}
      </h5>
      <h5>
        {{ $t("service.users") }}: {{ group.users_count }}
      </h5>
    </div>
    <div class="flex-shrink-1">
      <div class="buttons-group-item">
        <b-button class="btn-primary m-r-10" @click="openGroup" :content="$t('settings.Edit')" v-tippy="{ placement : 'top',  arrow: true }">
          <i class="feather icon-edit"></i>
        </b-button>
        <b-button class="btn-danger" @click="$refs['delete-modal'].show()" :content="$t('search.deleteTitle')" v-tippy="{ placement : 'top',  arrow: true }"><i class="feather icon-trash"></i></b-button>
      </div>
    </div>
  </div>

  <div v-show="isMobile" style="border: solid 1px rgba(0,0,0,0.1);border-radius: 4px; padding: 8px; display: flex; justify-content: space-between; margin-bottom: 4px">
    <div>
      <div>
        <h5 style="font-weight: 700;margin-bottom: 0">
          {{ group.name }}
        </h5>
        <div class="f-w-700">{{'('+ group.owner.login+')'}}</div>
      </div>
      <h5 style="margin-right: 0px">
        {{ $t("service.users") }}: {{ group.users_count }}
      </h5>
      <h5>
        {{ $t("service.bases") }}: {{ group.services_count }}
      </h5>
    </div>
    <div class="buttons" style="width: 30%; display: flex; flex-direction: column">
      <b-button class="btn-primary " @click="openGroup"><i class="feather icon-edit"></i></b-button>
      <b-button class="btn-danger" @click="$refs['delete-modal'].show()"><i class="feather icon-trash"></i></b-button>
    </div>


  </div>

  <b-modal ref="delete-modal" hide-footer centered :title="$t('group.are_you_sure_you_want_to_delete_the_group')">
    <div class="d-grid">
      <b-button class="mt-3" variant="danger" block @click="confirmCustomDelete">{{ $t("modal.actionDelete") }}</b-button>
      <b-button class="mt-2" variant="secondary" block @click="hideModal">{{ $t('modal.cancel') }}</b-button>
    </div>
  </b-modal>
</div>
</template>

<script>
export default {
  name: "service",
  props:['group'],
  data() {
    return {
      loading: false,
      isMobile: false
    }
  },
  methods: {
    openGroup() {
      this.$router.push({name: 'ServicesMonitoringGroupEdit', params: {id: this.group.id}})
    },
    hideModal() {
      this.$refs['delete-modal'].hide();
    },
    confirmCustomDelete() {
      this.hideModal();
      this.loading = true;
      this.$store.dispatch('serviceMonitoringGroup/deleteServiceGroup', this.group).then(() => {
        this.$store.dispatch('serviceMonitoringGroup/getGroupList', {}).then(() => {
          this.loading = false;
        })
      })
    }
  },
  mounted() {
    this.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent);
  }
}
</script>

<style lang="scss">
.media {

  margin-bottom: 15px;
}
h5{
  margin-right: 1rem;
  margin-bottom: 0 !important;
}
.group-name {
  width: 25%;
  font-weight: 700;
}
.group-name+h5 {
  //width: 70px;
}
.buttons-group-item {
  //width: 150px;
  display: flex;
}
</style>